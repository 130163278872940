<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="placesParts.placesPartsData">
      <PlacesPartsTable
        v-on:setPlacePartData="placePartData.fillData($event)"
        :placesPartsData="placesParts.placesPartsData"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="placesParts.pagination"
        v-on:changePage="changePage"
      />
      <PlacePartInfo :placePartData="placePartData" />
    </div>

    <PlacePartFloatBtns
      :theFilterData="placesParts.filterData"
      :placeToken="placeToken"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import PlacesPartsTable from "@/components/places/placesParts/PlacesPartsTable.vue";
import PlacePartInfo from "@/components/places/placesParts/PlacePartInfo.vue";
import PlacePartFloatBtns from "@/components/places/placesParts/PlacePartFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import PlacesParts from "@/models/places/placesParts/PlacesParts";
import PlacePart from "@/models/places/placesParts/PlacePart";

export default {
  name: "PlacesParts",
  components: {
    PreLoader,
    ExceptionWithImg,
    PlacesPartsTable,
    PlacePartInfo,
    PlacePartFloatBtns,
    Pagination,
  },
  props: {
    placeToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      placesParts: new PlacesParts(),
      placePartData: new PlacePart(),
    };
  },
  methods: {
    changePage(page) {
      this.placesParts.pagination.selfPage = page;
      this.getAllPlacesParts();
    },
    search(data) {
      this.placesParts.filterData.fillData(data);
      this.placesParts.placesPartsData = null;
      this.getAllPlacesParts();
    },
    async getAllPlacesParts() {
      this.isLoading = true;
      try {
        const response = await this.placesParts.placePart.getAllPlacesParts(
          this.language,
          this.userAuthorizeToken,
          this.placesParts.pagination,
          this.placesParts.filterData
        );
        console.log(response.data);
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.placesParts.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.placesParts.placesPartsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.placesParts.placesPartsData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.placesParts.placesPartsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.placesParts.placesPartsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.placesParts.filterData.placeToken = this.placeToken;
    this.getAllPlacesParts();
  },
};
</script>

