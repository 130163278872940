<template>
  <div class="my-edit-form">
    <!-- <h1>{{ $route.params.placeToken }}</h1> -->
    <header id="PlacePartInfo___BV_modal_header_" class="modal-header">
      <h5 id="PlacePartInfo___BV_modal_title_" class="modal-title">
        <h3>
          <img src="/img/transaction-points.ec75ad5d.svg" class="icon-lg" />
          <span v-if="language == 'en'">Place parts edit</span>
          <span v-if="language == 'ar'">تعديل اجزاء الملاعب</span>
        </h3>
      </h5>
    </header>
    <div class="my-edit-form-div">
      <PreLoader v-if="isLoading" />
      <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

      <PlacePartForm
        class="mt-5"
        :placePartData="placePartData"
        :submitName="$t('add')"
        v-on:addOrUpdatePlacePart="addOrUpdatePlacePart()"
      />
      <!-- ================= -->
      <div v-if="language == 'en'" class="buttons-action-e-d">
        <div class="">
          <button class="btn text-white btn-cancel" @click="hideNow()">
            cancel
          </button>
        </div>
        <div>
          <button class="btn btn-primary btn-submit">Edit</button>
        </div>
      </div>
      <!-- =================================== -->
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import PlacePartForm from "@/components/places/placesParts/PlacePartForm.vue";
import PlacePart from "@/models/places/placesParts/PlacePart";
import createToastMixin from "@/utils/create-toast-mixin";

import { formateDateTimeLang } from "@/utils/functions";

export default {
  name: "PlacePartEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    PlacePartForm,
  },
  props: {
    placeToken: {
      type: String,
      default: "",
    },
    editPart: {
      type: Boolean,
    },
    placesPartsData: {
      type: Array,
    },
  },
  async mounted() {
    this.removeButtons(); // Call the method to remove the buttons
    this.removeFormFooter();
  },
  data() {
    return {
      buttons: [],

      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      placePartData: new PlacePart(),
    };
  },
  methods: {
    removeFormFooter() {
      const formFooter = document.querySelector(".form-footer");
      const parentElement = formFooter.parentNode;

      if (parentElement) {
        parentElement.removeChild(formFooter);
      }
    },
    removeButtons() {
      // const formFooter = this.$el.querySelector(".form-footer");
      // const buttons = formFooter.querySelectorAll("button");
      // buttons.forEach((button) => {
      //   formFooter.removeChild(button);
      // });
      // // Update the component's data or computed property that holds the buttons
      // this.buttons = Array.from(buttons);
    },

    // ============================

    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    async addOrUpdatePlacePart() {
      this.isLoading = true;
      try {
        let response = await this.placePartData.addOrUpdatePlacePart(
          this.language,
          this.userAuthorizeToken
        );
        console.log(response);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.placePartData.setInitialValue();
          // this.placePartData.placeToken = this.placeToken;
          this.placeToken = this.placePartData.placeToken;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    hideNow() {
      this.$emit("hideNow");
    },
  },
  async created() {
    this.placeToken = this.placePartData.placeToken;
  },
};
</script>
<style lang="scss" scoped>
.my-edit-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background: white !important;
}
.none {
  display: none;
}
.buttons-action-e-d {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin: 20px;
  & button {
    margin: 10px;
    color: white;

    margin-inline-start: 5px;
    border-radius: 25px 5px;
    font-size: 18px;
    padding: 5px 20px;
    min-width: 130px;
  }
}

.my-edit-form-div {
  margin: 30px;
}
</style>
