<template>
  <div v-if="placesPartsData" class="">
    <!-- new edit m -->
    <div>
      <placePartEdit
        v-show="editPart"
        v-on:hideNow="hideNow()"
        :editPart="editPart"
        :placesPartsData="placesPartsData"
      />
    </div>
    <!-- end edit m -->
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("placesParts.code") }}</th>
            <th>{{ $t("placesParts.name") }}</th>
            <th>{{ $t("places.name") }}</th>
            <th>{{ $t("placesParts.description") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("actionDateTime") }}</th>
            <th>{{ $t("info") }}</th>
            <!-- <th>{{ $t("edit") }}</th> -->
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in placesPartsData" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(item.placePartCode) }}</td>
            <td>{{ isDataExist(item.placePartNameCurrent) }}</td>
            <td>{{ isDataExist(item.placeNameCurrent) }}</td>
            <td>{{ isDataExist(item.placePartDescriptionCurrent) }}</td>
            <td>{{ isDataExist(item.placePartNotes) }}</td>
            <td>
              {{ formateDateTimeLang(item.actionDate, item.actionTime) }}
            </td>
            <td>
              <button
                v-b-modal.PlacePartInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setPlacePartData(item)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <!-- <td>
              <button v-b-modal.PlacePartEdit @click="editPlaceParts()">
                <img src="/img/pencil.afad546c.svg" class="icon-lg" />
              </button>
            </td> -->
            <td>
              <button @click="deletePlacePart(item.placePartToken)">
                <img src="/img/trash.e8ae5ee4.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { isDataExist, formateDateTimeLang } from "@/utils/functions";

import placePartEdit from "@/components/places/placesParts/placePartEdit.vue";
import { BASE_URL } from "@/utils/constants.js";
import axios from "axios";

export default {
  name: "PlacesPartsTable",
  components: {
    placePartEdit,
  },
  data() {
    return {
      editPart: false,
      deletePlaceParts: false,

      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: {
    placesPartsData: {
      type: Array,
    },
  },
  methods: {
    hideNow() {
      this.editPart = false;
    },
    setPlacePartData(item) {
      // console.log(item);
      this.$emit("setPlacePartData", item);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    // ==========================
    async editPlaceParts() {
      this.editPart = true;
    },
    async deletePlacePart(placePartToken) {
      console.log("delete");
      this.deletePlaceParts = true;
      try {
        const response = axios.post(
          `${BASE_URL}/PlaceParts/FinalDeletePlacePart`,
          {
            placePartToken: placePartToken,
            language: this.language,
            userAuthorizeToken: this.userAuthorizeToken,
          }
        );
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {},
  async created() {},
};
</script>

